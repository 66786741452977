import './App.css';
import nameHere from './NameHere.png'
import Tree from './Tree';
import NameHeader from './NameHeader';
import { Link } from 'react-router-dom';

function LandingPage() {
  return (
    <div className="App">
      <center>
        <NameHeader name={"\"Insert Name Here\""} />
        <Tree image={nameHere} />
        <div className="divider"></div>
        <Link to={"/dicks"}><p className="pricing">All Verified Huge Dicks</p></Link>
        <div className="divider"></div>
        <p className="pricing">Pricing:</p>
        <div>
          <p className="pricing">1 Month: $50</p>
        </div>
        <div>
          <p className="pricing">1 Year: $100</p>
        </div>
        <div className="divider"></div>
        <p className="instructions">
          Instructions:
        </p>
        <p className="instructions">
          1. Email "name" + photo + pricing selection to <a href="mailto:info@thehugedick.com">info@thehugedick.com</a>
        </p>
        <p className="instructions">
          2. Our team will get back to you within 24 hours with contract information and invoicing details
        </p>
      </center>
    </div>
  );
}

export default LandingPage;
