import tree from "./Tree.png"

function Tree(props) {
  return (
    <div>
      <div style={{backgroundImage: `url(${props.image})`, maxWidth: "1600px"}} class="treeTop"></div>
      <div style={{width: "100%"}}>
      <img style={{width: "40vw", maxWidth: "800px"}} src={tree} alt="tree" />
      </div>
    </div>
  );
}

export default Tree;
