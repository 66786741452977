import './App.css';
import firebase from 'firebase';
import LandingPage from './LandingPage.js';
import NamePage from './NamePage.js';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import DicksPage from './DicksPage';
import LoginPage from './LoginPage';
import AddPage from './AddPage';


function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact={true} component={LandingPage} />
        <Route path='/add' component={AddPage} />
        <Route path='/login' component={LoginPage} />
        <Route path='/dicks' component={DicksPage} />
        <Route path='/:id' component={NamePage} />
      </Switch>
    </BrowserRouter>
  );
}

function setupFirebase() {
  var firebaseConfig = {
    apiKey: "AIzaSyBl6DTOhymw_qWc_W6RUmsF549kZljWLys",
    authDomain: "thehugedick-c6bc2.firebaseapp.com",
    databaseURL: "https://thehugedick-c6bc2-default-rtdb.firebaseio.com",
    projectId: "thehugedick-c6bc2",
    storageBucket: "thehugedick-c6bc2.appspot.com",
    messagingSenderId: "734735599525",
    appId: "1:734735599525:web:82295f53d6b43e37974a4c",
    measurementId: "G-26R65T6HEV"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

setupFirebase()

export default App;
