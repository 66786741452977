import { useRouteMatch } from 'react-router';
import firebase from 'firebase';
import NameHeader from './NameHeader';
import Tree from './Tree';
import './App.css';
import { useEffect, useState } from 'react';

function NamePage() {
  const match = useRouteMatch()
  const id = match.params.id
  const [dick, setDick] = useState(null)
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (dick) {
      document.title = `${dick.name} | The Huge Dick`
    }
  }, [dick])
  useEffect(() => {
      return firebase.database().ref(`dicks/${id}`).on("value", (snapshot) => {
          if (snapshot && snapshot.exists()) {
            setDick(snapshot.val())
          }
          setLoaded(true)
      })
  }, [id])
  if (!loaded) {
    return null
  }
  if (!dick) {
    return <center><h1 className={"title"}>Not Found</h1></center>
  }
  return (
    <center>
      <NameHeader name={dick.name} />
      <Tree image={dick.photoUrl} />
    </center>
  );
}

export default NamePage;
