import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import verified from './verified.png'

function DicksPage() {
    const [dicks, setDicks] = useState()
    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
        return firebase.database().ref("dicks").orderByChild("length").on("value", (snapshot) => {
            if (snapshot && snapshot.exists()) {
                setDicks(snapshot.val())
            }
        })
    }, [])
    useEffect(() => {
        return firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                setLoggedIn(true)
            }
        });
    }, [])
    return (
        <center>
            <h1 className="title">Verified Huge Dicks</h1>
            {loggedIn && <Link to="/add">[Admin] Add Huge Dick</Link>}
            <div style={{padding:"10px"}}>
                <table id="dicks">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Length (inches)</th>
                            <th style={{textAlign: "center"}}>Link</th>
                        </tr>
                        {dicks && Object.keys(dicks).map((key) => {
                            const value = dicks[key]
                            return (<tr>
                                <td>{value.name}</td>
                                <td>{value.length}</td>
                                <td style={{textAlign: "center"}}><Link to={`/${key}`}><img style={{width:"22px", height: "22px"}} src={verified} alt="verified" /></Link></td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </center>
    )
}

export default DicksPage