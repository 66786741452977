import firebase from 'firebase';
import { useState } from 'react';

function AddPage() {
    const [name, setName] = useState("")
    const [length, setLength] = useState("")
    const [photoUrl, setPhotoUrl] = useState("")
    return (
        <div>
            <div>
        <label> 
        Name:
        <input type="text" name="name" onChange={(event) => {
            setName(event.target.value)
        }} />
        </label>
        </div>
        <div>
        <label> 
        Length:
        <input type="text" name="length" onChange={(event) => {
            setLength(event.target.value)
        }} />
        </label>
        </div>
        <div>
        <label> 
        Photo Url:
        <input type="text" name="photoUrl" onChange={(event) => {
            setPhotoUrl(event.target.value)
        }} />
        </label>
        </div>
        <button onClick={() => {
            const shortName = name.replace(" ", "")
            firebase.database().ref(`dicks/${shortName}`).set({
                name: name,
                length: length,
                photoUrl: photoUrl
            })
        }}>Add</button>
        </div>
    )
}

export default AddPage