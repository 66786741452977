import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

function LoginPage() {
    const [loggedIn, setLoggedIn] = useState(false)
    useEffect(() => {
        console.log("Logging you in")
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              console.log("User signed in already" + JSON.stringify(user))
              setLoggedIn(true)
            } else {
                var provider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithRedirect(provider).then(() => {
                    console.log("Login Completed Successfully")
                }).catch((error) => {
                    console.log(error)
                });
            }
          });
    })
    if (loggedIn) {
        return <Redirect to="/admin" />
    }
    return <h1>Logging You In</h1>
}

export default LoginPage